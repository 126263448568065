.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 7px 35px;
    font-size: 24px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    position: fixed;
    width: 100%;
    top: 0;
    background-color: #FBF7F4;
    z-index: 20;
    height: max-content;
}

.nav-left {
    display: flex;
    justify-content: flex-start;
    width: 30%;
}

.nav-middle {
    display: flex;
    justify-content: center;
    width: 60%;
}

.logoSize {
    width: 70%;
}

.nav-right {
    display: flex;
    justify-content: flex-end;
    width: 30%;
    align-items: inherit;
}

.connectDiv {
    display: flex;
    width: max-content;
    font-size: smaller;
}

.profileDiv {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    cursor: pointer;
    color: white;
    font-weight: 900;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: small;
    padding: 5px;
}

#acc-form {
  max-width: 400px;
  margin: 10px auto;
  padding: 20px 20px;
  border-radius: 8px;
  background-color: #fff;
}

#acc-form h1 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
  text-align: center;
}

.form-el {
  margin-bottom: 2.5rem;
}

.form-el select {
  width: 100%;
  cursor: pointer;
  padding: 15px 20px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  background-color: #f9f9f9;
  transition: border-color 0.3s, box-shadow 0.3s;
}



.form-el select option {
  border-radius: 0.5rem;
} 




#request-callback {
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s, transform 0.2s;
}


#request-callback:active {
  transform: scale(0.98);
}

#acc-form .successbox{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

#acc-form .successbox .heading-line{
  height: 2px;
  border: none;
  border-bottom: 2px solid green;
  width: 5rem;
}

#acc-form .successbox .heading-line.error{
  border-bottom: 2px solid red;
}



#acc-form h1.accountDetailHead{
  margin-bottom: 2rem;
}


#acc-form h1.success {
  color: #4CAF50;
  font-size: 32px !important;;
}

#acc-form h1.error {
  color: red;
  font-size: 32px !important;;
}


#acc-form h2.message {
  color: #555;
  font-size: 18px !important;;
  padding-top: 20px;
}

.form-el.callback_btn{
  margin-bottom: 0;
}

@media (max-width: 480px) {
  #acc-form {
    padding: 10px;
    padding-left: 4px;
    padding-right: 4px;
    max-width: 300px;
  }

  .additional-details{
      max-width: 400px;
  }

  #acc-form h1 {
    font-size: 1.25rem;
  }

  #request-callback {
    font-size: 0.9rem;
  }



  #acc-form .successbox{
    gap: 6px;
  }

  #acc-form .successbox .heading-line{
    width: 3rem;
  }

  #acc-form h1.success {
      font-size: 26px !important;
    }

    #acc-form h1.error {
      font-size: 25px !important;
    }

    #acc-form h2.message {
      color: #555;
      font-size: 17px !important;
      padding-top: 10px;
    }
}

@media (min-width: 600px) and (max-width: 1200px) {
    .brandIcon svg {
        min-height: 22px;
    }

    .navBrandText {
        display: block;
    }

    .profileDiv {
        width: 35px !important;
        height: 35px !important;
        min-width: 35px;
        min-height: 35px;
    }
}


@media screen and (max-width:600px) {
    .nav-middle {
        display: flex;
        justify-content: center;
        width: 60%;
        text-align: center;
        font-size: 1rem;
    }

    .profileDiv {
        width: 30px !important;
        height: 30px !important;
    }

    .logoSize {
        width: 60%;
    }

    .navbar {
        padding: 7px;
    }

    .nav-left {
        display: flex;
        justify-content: flex-start;
        width: 48%;
    }

    .connectDiv {
        font-size: x-small;
    }

    .nav-right {
        width: auto;
    }

}