.toast {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 10px 20px;
    border-radius: 4px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    animation: fadeIn 0.3s, fadeOut 0.3s 2.7s;
    width: max-content;
    max-width: 90%;
  }
  
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      bottom: 0;
    }
    to {
      opacity: 1;
      bottom: 20px;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
      bottom: 20px;
    }
    to {
      opacity: 0;
      bottom: 0;
    }
  }

@media screen and (max-width: 600px) {
  .toast {
    font-size: small;
  }
}
  